@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*, html, body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
            "slnt" 0;
}

.text-metcon {
    color: #FEC106;
}

.text-metcon:hover {
    color: #FEC106 !important;
}

.bg-metcon {
    background-color: #FEC106;
}

.bg-metcon:hover {
    opacity: 0.8;
    background-color: #FEC106 !important;
}